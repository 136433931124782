import { Form } from "react-bootstrap";
import { UseAppContext } from "../../../context/appContext";
import { useDispatch, useSelector } from "react-redux";
import IClient from "../../../interfaces/IClient";
import { useEffect, useState } from "react";
import IWorkoutRequest from "../../../interfaces/workouts/IWorkoutRequest";
import { FormEvent } from "../../../types/FormEvent";

import IWorkoutFull from "../../../interfaces/workouts/IWorkoutFull";
import { setStoreWorkoutRequest } from "../../../store/workoutReducer";
import { AppDispatch } from "../../../store/actionTypes";
import { useParams } from "react-router-dom";

interface props {
  currentWorkout?: IWorkoutFull;
  hide?: () => void;
  showCreating: (workoutToSend: IWorkoutRequest) => void;
  action: boolean;
}

export default function ClientWorkoutForm({
  currentWorkout,
  hide,
  showCreating,
  action,
}: props) {
  const { translate } = UseAppContext();
  const storedClient = useSelector(
    (state: any) => state.client.client
  ) as IClient;
  const clients = useSelector(
    (state: any) => state.client.clients
  ) as Array<IClient>;
  const [workout, setWorkout] = useState({} as IWorkoutRequest);
  const dispatch: AppDispatch = useDispatch();
  const { client: clientId } = useParams();
  const [client, setClient] = useState((storedClient || {}) as IClient);

  useEffect(() => {
    if (action) {
      generateWorkout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  useEffect(() => {
    if (!client?.id && clients?.length) {
      const newClient = clients.find((client) => client.id === clientId);
      if (newClient) {
        setClient(newClient);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedClient, client, clients]);

  useEffect(() => {
    const newWorkout = {} as IWorkoutRequest;
    newWorkout.client = client.id;
    newWorkout.age = client.age;
    newWorkout.fitness = client.fitnessLevel;
    newWorkout.gender = client.gender;
    newWorkout.weight = client.weight;
    newWorkout.goals = client.goals;
    newWorkout.limitations = client.limitations;
    newWorkout.info = client.info;
    newWorkout.lang = "pt";
    newWorkout.langName = "Português de Brasil";
    setWorkout(newWorkout);
  }, [client, currentWorkout, action]);

  const handleChange = (e: FormEvent) => {
    const { name, value } = e.target;
    setWorkout((prevWorkout) => ({
      ...prevWorkout,
      [name]: value,
    }));
  };

  const handleNumberChange = (e: FormEvent) => {
    const { name, value } = e.target;
    setWorkout((prevWorkout) => ({
      ...prevWorkout,
      [name]: Number(value),
    }));
  };

  async function generateWorkout() {
    const workoutToSend = { ...workout } as IWorkoutRequest;
    workoutToSend.weeksNumber = workout.weeksNumber || 2;
    workoutToSend.perWeek = workout.perWeek || 3;

    const numberOfExercises =
      workoutToSend.weeksNumber * workoutToSend.perWeek * 6;

    workoutToSend.numberOfExercises = numberOfExercises;

    dispatch(setStoreWorkoutRequest(workoutToSend));
    return showCreating(workoutToSend);
  }

  return (
    <div className="px-2">
      {client?.id ? (
        <Form className="mb-2 text-start mx-2">
          <Form.Group controlId="gender">
            <Form.Label>{translate("gender")}</Form.Label>
            <Form.Control
              type="text"
              name="gender"
              placeholder={translate("genderInfo")}
              value={workout.gender || ""}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="age">
            <Form.Label>{translate("ageYears")}</Form.Label>
            <Form.Control
              type="number"
              name="age"
              value={workout.age || ""}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="weight">
            <Form.Label>{translate("weightKg")}</Form.Label>
            <Form.Control
              type="number"
              name="weight"
              value={workout.weight || ""}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="fitness">
            <Form.Label>{translate("fitnessInfo")}</Form.Label>
            <Form.Control
              type="text"
              name="fitness"
              placeholder={translate("fitnessExample")}
              value={workout.fitness || ""}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="goals">
            <Form.Label>{translate("goals")}</Form.Label>
            <Form.Control
              type="text"
              name="goals"
              placeholder={translate("goalsExample")}
              value={workout.goals || ""}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="limitations">
            <Form.Label>{translate("limitations")}</Form.Label>
            <Form.Control
              type="text"
              name="limitations"
              as="textarea"
              placeholder={translate("limitationsInfo")}
              value={workout.limitations || ""}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="info">
            <Form.Label>{translate("infos")}</Form.Label>
            <Form.Control
              type="text"
              name="info"
              as="textarea"
              placeholder={translate("extraInfo")}
              value={workout.info || ""}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="trainings">
            <Form.Label>{translate("trainingsTypes")}</Form.Label>
            <Form.Control
              type="text"
              name="trainings"
              value={workout.trainings || ""}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="weeksNumber">
            <Form.Label>{translate("weeks")}</Form.Label>
            <Form.Control
              type="number"
              name="weeksNumber"
              placeholder={translate("weeksInfo")}
              value={workout.weeksNumber || "2"}
              onChange={handleNumberChange}
              min={1}
              max={4}
            />
          </Form.Group>

          <Form.Group controlId="perWeek">
            <Form.Label>{translate("trainingsPerWeek")}</Form.Label>
            <Form.Control
              type="number"
              name="perWeek"
              placeholder={translate("trainingPerWeekInfo")}
              value={workout.perWeek || "3"}
              onChange={handleNumberChange}
              min={1}
            />
          </Form.Group>

          <Form.Group controlId="equipments">
            <Form.Label>{translate("equipments")}</Form.Label>
            <Form.Control
              type="text"
              name="equipments"
              placeholder={translate("equipmentsInfo")}
              as="textarea"
              value={workout.equipments || ""}
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      ) : (
        <div>{translate("noClientSelectedYet")}</div>
      )}
    </div>
  );
}

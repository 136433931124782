import { Button, Card, Modal } from "react-bootstrap";
import { UseAppContext } from "../../../context/appContext";
import WorkoutForm from "./ClientWorkoutForm";
import IWorkoutFull from "../../../interfaces/workouts/IWorkoutFull";
import IWorkoutRequest from "../../../interfaces/workouts/IWorkoutRequest";
import { useState, useEffect } from "react";

interface props {
  show: boolean;
  hide: () => void;
  currentWorkout?: IWorkoutFull;
  showCreating: (workoutToSend: IWorkoutRequest) => void;
}

export default function ClientWorkoutModal({
  show,
  hide,
  currentWorkout,
  showCreating,
}: props) {
  const { translate } = UseAppContext();
  const [action, setAction] = useState(false);

  useEffect(() => {
    setAction(false);
  }, [currentWorkout]);

  return (
    <Modal
      show={show}
      onHide={() => {
        hide();
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="border-none"
    >
      <Modal.Header className="py-3 text-center">
        <h4 className="text-center my-0 mx-auto">
          {translate("trainingData")}
        </h4>
      </Modal.Header>

      <Modal.Body className="p-0">
        <Card
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            maxHeight: "calc(85vh - 80px)",
            border: "none",
          }}
          className="px-2"
        >
          <WorkoutForm
            currentWorkout={currentWorkout}
            hide={hide}
            showCreating={showCreating}
            action={action}
          />
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            setAction(true);
          }}
        >
          {translate("generateWorkout")}
        </Button>
        <Button onClick={hide} variant="secondary">
          {translate("cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

import { Modal, Spinner } from "react-bootstrap";
import { axiosContext, UseAppContext } from "../../../context/appContext";
import IResponse from "../../../interfaces/IResponse";
import IWorkoutFull from "../../../interfaces/workouts/IWorkoutFull";
import {
  fetchClientWorkouts,
  fetchUserWorkouts,
  setCreating,
  setStoreCurrentSimpleWeeks,
  setStoreCurrentWeeks,
  setStoreWorkout,
} from "../../../store/workoutReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store/actionTypes";
import IWorkoutRequest from "../../../interfaces/workouts/IWorkoutRequest";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface props {
  show: boolean;
  hide: () => void;
  workoutToSend: IWorkoutRequest;
}

export default function CreatingWorkoutModal({
  show,
  hide,
  workoutToSend,
}: props) {
  const { translate } = UseAppContext();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const creating = useSelector(
    (state: any) => state.workout.creating
  ) as Boolean;

  useEffect(() => {
    if (workoutToSend && !creating && show) {
      createWorkout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workoutToSend, show]);

  async function createWorkout() {
    dispatch(setCreating(true));

    const isClient = Boolean(workoutToSend.client?.length);
    let newWorkoutId = "";

    try {
      if (isClient) {
        const response = await axiosContext.post(
          "/app/workouts/generateWorkoutStructure",
          workoutToSend
        );

        const data = response.data as IResponse;

        if (data.success) {
          const newResponse = { ...response.data.data } as IWorkoutFull;
          newWorkoutId = newResponse.id || "";

          dispatch(fetchClientWorkouts(workoutToSend.client || ""));
          dispatch(setStoreWorkout(newResponse));
          dispatch(setStoreCurrentSimpleWeeks(newResponse?.simpleWeeks || []));
          dispatch(setStoreCurrentWeeks(newResponse?.weeks || []));
          toast.success(translate("successCreateWorkout"), {
            position: "top-center",
            autoClose: 5000,
          });
        } else {
          toast.error(translate(data.info), {
            position: "top-center",
            autoClose: 7000,
          });
          hide();
        }
      } else {
        const response = await axiosContext.post(
          "/app/workouts/generateUserWorkoutStructure",
          workoutToSend
        );

        const data = response.data as IResponse;

        if (data.success) {
          const newResponse = { ...response.data.data } as IWorkoutFull;
          newWorkoutId = newResponse.id || "";

          dispatch(fetchUserWorkouts());
          dispatch(setStoreWorkout(newResponse));
          dispatch(setStoreCurrentSimpleWeeks(newResponse?.simpleWeeks || []));
          dispatch(setStoreCurrentWeeks(newResponse?.weeks || []));
          toast.success(translate("successCreateWorkout"), {
            position: "top-center",
            autoClose: 5000,
          });
        } else {
          toast.error(translate(data.info), {
            position: "top-center",
            autoClose: 7000,
          });
          hide();
        }
      }
    } catch (error: any) {
      console.error("Error fetching data:", error);
      dispatch(setCreating(false));
      toast.error(translate(error.toString), {
        position: "top-center",
        autoClose: 5000,
      });
    }

    dispatch(setCreating(false));

    if (isClient) {
      return navigate(`/client/${workoutToSend.client}/${newWorkoutId}`);
    }

    navigate(`/workout/${newWorkoutId}`);
    hide();
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        hide();
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="border-none"
    >
      <Modal.Body>
        <div className="d-flex flex-column py-2">
          <h6 className="mx-auto text-center">
            {translate("creatingWorkout")}
          </h6>
          <p className="mx-auto text-center">
            {translate("creatingWorkoutInfo")}
          </p>
          <Spinner className="mx-auto mb-3" />
          <p
            className="mx-auto text-center mb-0 px-3"
            style={{ lineHeight: 1.2 }}
          >
            <small>{translate("creatingWorkoutTime")}</small>
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

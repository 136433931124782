import { useDispatch, useSelector } from "react-redux";
import WorkoutCard from "../components/workouts/card/WorkoutCard";
import AppContainer from "../containers/AppContainer";
import IWorkoutFull from "../interfaces/workouts/IWorkoutFull";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  fetchSubscriptionWorkout,
  fetchUserWorkouts,
  setStoreCurrentSimpleWeeks,
  setStoreCurrentWeeks,
  setStoreCurrentWorkout,
} from "../store/workoutReducer";
import { axiosContext } from "../context/appContext";
import { AppDispatch } from "../store/actionTypes";

export default function WorkoutPage() {
  const { workout, client, professor } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const [currentWorkout, setCurrentWorkout] = useState({} as IWorkoutFull);
  const loadedProfile = useSelector(
    (state: any) => state.user.loadedProfile
  ) as boolean;

  const storedWork = useSelector(
    (state: any) => state.workout.workout
  ) as IWorkoutFull;

  useEffect(() => {
    if (storedWork) {
      if (
        currentWorkout?.id !== storedWork?.id ||
        currentWorkout.title !== storedWork?.title
      ) {
        setCurrentWorkout(storedWork);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedWork]);

  useEffect(() => {
    async function getWorkout() {
      if (professor && workout) {
        dispatch(
          fetchSubscriptionWorkout({ workout: workout, professor }) as any
        );

        return;
      }

      let requestUrl = `/app/workouts/getWorkout?id=${workout}`;

      if (client && client.length) {
        requestUrl = requestUrl + `&client=${client}`;
      }

      await axiosContext
        .get(requestUrl)
        .then((response) => {
          if (response.data?.success) {
            const newWorkout = response.data?.data as IWorkoutFull;

            setCurrentWorkout(newWorkout);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    if (loadedProfile) {
      getWorkout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout, loadedProfile, client]);

  useEffect(() => {
    if (currentWorkout) {
      if (currentWorkout.weeks && currentWorkout.weeks.length) {
        dispatch(setStoreCurrentWeeks(currentWorkout.weeks));
      }

      if (currentWorkout.simpleWeeks && currentWorkout.simpleWeeks.length) {
        dispatch(setStoreCurrentSimpleWeeks(currentWorkout.simpleWeeks));
      }

      dispatch(setStoreCurrentWorkout(currentWorkout));
    } else {
      dispatch(fetchUserWorkouts() as any);
    }
  }, [currentWorkout, dispatch]);

  return (
    <AppContainer>
      <div className="py-3 px-2">
        {currentWorkout && <WorkoutCard currentWorkout={currentWorkout} />}
      </div>
    </AppContainer>
  );
}
